.reportCard{
  border-radius: 15px;
  /* box-shadow: 0 7px 6px -1px #777; */
  box-shadow: 0 6px 6px -3px gray;
 }

 .reportCard h3,
 .reportCard h5{
  color: var(--primaryColor);
 }


.reportCard:hover, h3.class > *{
     background-color: #463c3c;
     color: #fff !important;
   -ms-transform: scale(1.3); /* IE 9 */
  -webkit-transform: scale(1.3); /* Safari 3-8 */
  transform: scale(1.1);
  cursor: pointer;
}

.reportCardSelected {
  background-color: #8a7b7b;
  border-radius: 15px;
  box-shadow: 0 6px 6px -3px gray;
}

 .reportCard span{
   font-weight:bold;
   font-size: 16px;
 }

.contentCard{
  border-radius: 8px;
  box-shadow: 0 6px 6px -3px gray;
}