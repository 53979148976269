.dashCard{
  border-radius: 15px;
  /* box-shadow: 0 7px 6px -1px #777; */
  box-shadow: 0 6px 6px -3px gray;
 }

 .dashCard h3,
 .dashCard h5{
  color: var(--primaryColor);
 }


.dashCard:hover, h3.class > *{
     background-color: #463c3c;
     color: #fff !important;
   -ms-transform: scale(1.3); /* IE 9 */
  -webkit-transform: scale(1.3); /* Safari 3-8 */
  transform: scale(1.1);
  cursor: pointer;
}

 .dashCard span{
   font-weight:bold;
   font-size: 16px;
 }

 .dashCard h3{
  font-size: 14px;
}



._border-radius-top{
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  box-shadow: 0 6px 6px -3px gray;
}

._border-radius-bottom{
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: 0 6px 6px -3px gray;
}

 /** POWER BI IFRAME*/
 .containerPI {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframePI {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
 /**End iframe*/